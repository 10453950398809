import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import { screen } from '@styles/mixins/breakpoints';
import GridContainer from '@components/GridContainer/grid-container';

const StyledMediaCentreContainer = styled.div`
  ${screen.l`
    padding-top: 48px;
  `}
`;

const MediaCentre = () => {
  const mediaList = useStaticQuery(
    graphql`{
  allJsonJson {
    nodes {
      media {
        id
        title
        imageUrl {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        downloadUrl
        itemCount
      }
    }
  }
}
`,
  );

  const elements = mediaList.allJsonJson.nodes.find(({ media }) => !!media)
    .media;

  const descriptionText = 'Use these downloads for press stories only.';

  return (
    <>
      <Helmet>
        <meta name="description" content={descriptionText} />
        <meta itemProp="description" content={descriptionText} />
        <meta property="og:description" content={descriptionText} />
        <meta property="twitter:description" content={descriptionText} />
      </Helmet>
      <StyledMediaCentreContainer>
        <GridContainer title="Media kit" elements={elements} isfMediaGrid />
      </StyledMediaCentreContainer>
    </>
  );
};

export default MediaCentre;
